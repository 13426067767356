<template>
  <AddShortlistingCriteriaLayout>
    <template v-slot:header>
      <Header @cancel="openModal">
      </Header>
    </template>
    <template v-slot:main-content>
      <div class="lg:tw-pl-64 tw-px-10 tw-pt-10 lg:tw-pt-20">
        <h4 class="font-weight-bold" style="font-size: 24px">Shortlisting Criteria</h4>
        <p>Shortlisting criteria helps system filter applicants that do not meet your set requirements.</p>
      </div>
      <div class="lg:tw-pl-64 lg:tw-pr-56 tw-px-10 tw-pb-5">
        <AddShortlistingCriteria/>
        <SplashConfirmModal :dialog="dialog" title="Cancel Add Criteria" sub-title="" @close="closeModal" @function="gotoDashboard"/>

      </div>

    </template>
    <template v-slot:footer>
      <div class="tw-relative  tw-flex-col" style="width:100vw;">
        <BottomNavbar :loading="criteria_loading" @click:back="goToBack" :button-name="$route.query.edit?'Save Changes':'Save Criteria'" button-width="9.63rem" @click:next="goToNext"
                      :previous="!$route.query.edit"/>
      </div>
    </template>

  </AddShortlistingCriteriaLayout>
</template>

<script>
import AddShortlistingCriteria from "../../components/shortlistingCriteria/components/AddShortlistingCriteria";
import AddShortlistingCriteriaLayout from "../../components/shortlistingCriteria/layouts/AddShortlistingCriteriaLayout";
import Header from "../../components/onboarding/reuseables/Header";
import BottomNavbar from "../../components/jobPosting/reuseables/BottomNavbar";
import {mapState} from "vuex";
// import JobSummary from "../JobSummary";
import getSiteName from "../../services/utils";
import SplashConfirmModal from "../../components/UIComponents/SplashConfirmModal";

export default {
  name: "ShortlistingView",
  components: {SplashConfirmModal, BottomNavbar, Header, AddShortlistingCriteriaLayout, AddShortlistingCriteria},
  data() {
    return {
      dialog: false,
      job_criteria: {},
      id: {
        job_id: null,
        criteria_id: null,
      },
      criteria_loading: false,
      buttonText: "Save Criteria"
    }
  },
  watch:{
    'this.$route.query.edit': function (val){
      if (val === 'true'){
        this.buttonText = "Save Changes"
      }

    }
  },
  methods: {
    gotoDashboard() {
      this.dialog = false
      return this.$router.push({name: "RecruiterDashboard"})
    },
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
    },
    prepareFieldDataForBackend() {
      console.log(this.fieldData)
      const obj = this.fieldData.reduce((acc, curr) => {
        console.log(curr.response)
        return {
          ...acc,
          [`use_${curr.type}`]: curr.enable,
          ...curr.response
        }
      }, {})
      obj.site_name = getSiteName()
      console.log(obj)
      this.job_criteria = obj
    },
    goToBack() {
      this.$router.push({name: 'Job-stepNine'})
    },
    async goToNext() {
      this.criteria_loading = true
      await this.prepareFieldDataForBackend();
      this.id.job_id = this.$route.params.jobId
      const {age_upper_limit, age_lower_limit} = this.job_criteria
      if (+age_upper_limit < +age_lower_limit) {
        this.$displaySnackbar({
          message: "Maximum Age should be higher than the Minimum Age",
          success: false
        })
        return
      }
      const {salary_upper_limit, salary_lower_limit} = this.job_criteria
      if (+salary_upper_limit < +salary_lower_limit) {
        this.$displaySnackbar({
          message: "Maximum salary should be higher than Minimum salary",
          success: false
        })
        return
      }
      try {
        if (!this.id.criteria_id) {
          let data = {
            ...this.id,
            ...this.job_criteria
          };
          console.log(data)
          await this.$store.dispatch('shortlisting/createCriteria', data)
          this.$displaySnackbar({
            message: 'Criteria set',
            success: true
          })
          // sessionStorage.removeItem('salary')
          await this.$router.push({name: 'JobSummary',query:this.id,params:{experience:this.job_criteria.experience}})
          this.criteria_loading = false

        } else {
          let data = {
            info: {
              ...this.id,
              ...this.job_criteria
            },
            id: this.id.criteria_id
          };
          try {
            await this.$store.dispatch('shortlisting/updateCriterias', data)
            this.$displaySnackbar({
              message: 'Criteria Updated',
              success: true
            })
            await this.$router.push({name: 'JobSummary',query:this.id,params:{experience:this.job_criteria.experience}})
            this.criteria_loading = false
          } catch (e) {
            this.criteria_loading = false
            this.$displaySnackbar({
              message: e.response.data.detail,
              success: false
            })
          }
        }
      } catch (e) {
        this.criteria_loading = false
        this.$displaySnackbar({
          message: e.data.detail,
          success: false
        })
      }
    }
  },
  computed: {
    ...mapState('shortlisting', ['fieldData', 'criteriaDetails', 'jobInfo'])
  },
  async created() {
    this.id.job_id = this.$route.params.jobId
    let data = {
      id: this.id.job_id,
      site_name: getSiteName()
    }
    await this.$store.dispatch('shortlisting/getJobDetails', data)
    await this.$store.dispatch('shortlisting/getCriterias', data)
    this.id.criteria_id = this.criteriaDetails.id
    console.log(this.$route.query.edit)
  },
}
</script>
<style scoped>
</style>
