const ENUM_CERTIFICATION = {
    certifications: [
        "REHS Certification",
        "Environmental Health and Safety Professional Certificate",
        "Certified Safety Professional Certification (CSP)",
        "Associate Safety Professional (ASP)",
        "Graduate Safety Practitioner (GSP)",
        "Safety Management Specialist (SMS)",
        "Occupational Health and Safety Technologist (OHST)",
        "Construction Health and Safety Technician (CHST)",
        "Safety Trained Supervisor (STS)",
        "Certified Environmental, Safety and Health Trainer (CET)",
        "Safety Trained Supervisor Construction (STSC)",
        "National Registry of Environmental Professionals (NREP) Certifications",
        "Certified Internal Auditor (CIA)",
        "Certified Fraud Examiner (CFE)",
        "Certification in Risk Management Assurance® (CRMA)",
        "Certified Information Systems Auditor (CISA)",
        "Chartered Global management accountant (CGMA)",
        "Certified Government Auditing Professional (CGAP)",
        "Certified Public Bookkeeping",
        "Certification in Control SeUCL lf-Assessment (CCSA)",
        "Certified Management Accountant",
        "Certified Financial Services Auditor (CFSA)",
        "PHR: Professional in Human Resources",
        "SPHR: Senior Professional in Human Resources",
        "SHRM-CP: SHRM Certified Professional",
        "PRHi: Professional in Human Resources",
        "SHRM-SCP: SHRM Senior Certified Professional",
        "GPHR: Global Professional in Human Resources",
        "SPHRi: Senior Professional in Human Resources — International",

        "Citrix Certification (Certified Associate – Visualization, Certified Associate – Networking, Certified Professional –Visualization)",
        "Certified in Risk and Information Systems Control (CRISC)",
        "Cisco Certification (Certified Network Associate (CCNA) Routing & Switching)",
        "Cisco Certification (Certified Network Professional (CCNP) Routing & Switching)",
        "Amazon Web Service (AWS) Certified Solutions Architect Certification",
        "Certified Information System Auditor (CISA)",
        "Project Management in IT Security (PMITS)",
        "Certified Information Systems Security Pro (CISSP)",
        "Certified Information Security Manager (CISM)",
        "Puppet Certification Program",
        "ITIL v3 Foundation",
        "VMware Certified Professional 5 – Data Center Virtualization (VCP5-DCV)",
        "Microsoft Certified Solutions Expert (MCSE) – Server Infrastructure",
        "Microsoft Certified Solutions Associate (MCSA) – Windows Server",
        "CompTIA Project+ certification",
        "AMA Professional Certified Marketer (PCM) Marketing Management Certifications",
        "Google Analytics",
        "AMA Professional Certified Marketer (PCM)",
        "Digital Marketing Certification",
        "Google Adwords Certification",
        "Hubspot Marketing Certifications",
        "Bing Ads Certificate",
        "Data & Marketing Association DMA Certification",
        "Content Marketing Institute Online Training and Certification",
        "DigitalMarketer Certified Content Marketing Specialist",
        "Facebook Blueprint",
        "Code Academy HTML",
        "Copyblogger Certified Content Marketer",
        "Hootsuite Certification",
        "Harvard Marketing Management Certification",
        "W3Schools Certifications (HTML5, CSS, JavaScript, jQuery, PHP, and XML)",
        "Social media communication Certification",
        "Professional Engineering License)",
        "Fundamentals of Engineering (FE)",
        "Certified Maintenance & Reliability Professional",
        "Associate System Engineering Professional",
        "Certifications in engineering Graphics",
        "Certified Technology Manager (CTM/CSTM)",
        "Quality Engineer certification (CQE)",
        "Certified Maintenance and Reliability Technician",
        "ISA Certified Automotive Professional",
        "IAPMO Mechanical Inspector",
        "Certified Manufacturing Engineering",
        "Solar Heating Installer Certification",
        "Certified Reliability Engineer",
        "High-Performance Building Design",
        "Geometric Dimensioning & Tolerancing Professional – Technologist",
        "Test and Balance Engineer",
        "Project Management Professional (PMP) certification",
        "Certified Association in Project Management CAPM",
        "Master Project Manager (MPM)",
        "Certified Project Manager (CPM)",
        "Professional in Project Management (PPM)",
        "Certified ScrumMaster (CSM)",
        "Agile DevOps Expert",
        "Certified ScrumMaster (CSM)",
        "Agile Scrum Foundation",
        "PMI-ACP Certification",
        "Certified Scrum Product Owner (CSPO)",
        "Atlassian Certified Professional (ACP)",
        "Certified Project Management Practitioner (CPMP)",
        "SAFe Agilist Certification Training",
        "Global Association for Quality Management (GAQM)/Associate in Project Management",
        "ASQ Quality Assurance Certifications",
        "Certified Professional Sales Person (CPSP) Credential",
        "Accredited in Medical Sales (AMS) programs",
        "Certified Sales Executive (CSE)",
        "Amazon Web Services Certification Training",
        "Certified Sales Operations Professional (CSOP)",
        "Salesforce Certifications",
        "Hubspot Sales Certification Course",
        "Consultative Sales Certification",
        "Certification in Consultative Sales Communication",
        "Certified Sales Leadership Professional (CSLP)",
        "Certification in Consultative Sales Strategies",
        "National Retail Federation Certification",
        "Microsoft Certified Professional Developer",
        "Microsoft (MTA)",
        "Amazon Web Services (AWS) Certified Developer",
        "Oracle Application Express Developer Certification (Oracle APEX)",
        "Cloudera Certified Developer for Apache Hadoop (CCDH)",
        "Oracle Certified Professional, (OCP) MYSQL 5.6 Developer",
        "Oracle Certified Professional OCP (Java SE Programmer, Java ME Mobile Application Developer)",
        "Oracle Certified Associate (OCA) Java SE Programmer",
        "CIW (Web Foundation Associate, Web Design Professional, Web & Mobile Design Professional, Web Development Professional)",
        "Red Hat JBoss certified Developer",
        "Puppet Labs Certification Program",
        "Salesforce Certified Developer & Advanced Developer",
        "Scrum Alliance Certified Scrum Developer (CSD)",
        "PMP Agile Certified Practitioner (PMI-ACP)",
        "Harvard Software Engineering Certificate",
        "Adobe certified expert (ACE)",
        "Campaign Architect",
        "AEM 6 Developer",
        "AEM 6 Developer Practitioner",
        "AEM 6 Lead Developer",
        "AEM 6 Architect",
        "AEM Form Developer",
        "Campaign Business Practitioner",
        "Adobe Illustrator CC 2015",
        "Campaign Developer",
        "Dreamweaver CC 2015",
    ].sort()
}
export default ENUM_CERTIFICATION;