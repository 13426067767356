<template>
  <div class="option-text-field d-flex align-center">
    <v-text-field
        :label="`Option ${order}`"
        :color="'#008eef'"
        solo
        hide-details
        :append-icon="
        correct
          ? 'mdi-checkbox-blank-circle'
          : 'mdi-checkbox-blank-circle-outline'
      "
        @click:append="$emit('select')"
        :id="`s${id}`"
        @input="emitInputValue"
        class="my-2"
        v-bind="$attrs"
    />
    <v-btn icon @click="$emit('click:delete', id)" id="delete">
      <XIcon size="1.1x"/>
    </v-btn>
  </div>
</template>

<script>
import {XIcon} from 'vue-feather-icons';
export default {
  components: { XIcon},
  name: "OptionTextField",
  data: () => ({
    value: "",
  }),
  props: {
    order: [String, Number],
    correct: Boolean,
    id: String,
  },
  methods: {
    emitInputValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped lang="scss">
.delete-option {
  transform: rotate(45deg);
}
</style>
