<template>
  <div>
    <v-card class="my-6 cardQue" flat width="500">
      <div class="pa-10">
        <div v-if="expand" class="d-flex justify-center align-center">
          <div style="flex: 1" class="font-weight-bold mt-3 d-flex">
            <!--          {{ order }}.-->
            <p class="question">{{ order }}. {{ question.question }}</p>
          </div>
          <v-btn text icon @click="expand = !expand" id="expand">
            <Edit2Icon size="1.4x"/>
          </v-btn>
          <v-btn
              text
              icon
              class="card-footer-action"
              @click="$emit('click:deleteQuestion')"
              id="deleteQuestion"
          >
            <Trash2Icon size="1.4x"/>
          </v-btn>
        </div>
        <div v-else class="d-flex justify-center align-center">
          <div style="flex: 1" class="font-weight-bold mt-3 d-flex">
            <!--          {{ order }}.-->
            <p class="question">Question {{ order }}.</p>
          </div>
        </div>
        <v-expand-transition>
          <div v-show="!expand" class="mt-5">
            <question-box
                :prop-question="question.content"
                :prop-selected="question.quiz_type"
                @change="emitSelectionChange"
                @input="emitQuestionValue"
                id="questionBox"
            />
            <transition name="fade">
              <span
                  v-show="optionTextFieldErrorMsg !== ''"
                  class="option-text-field-error-msg"
              >{{ optionTextFieldErrorMsg }}</span
              >
            </transition>

              <option-text-field
                  v-for="(option, index) in question.options"
                  :key="index"
                  :id="option.id"
                  @select="$emit('select', option.id)"
                  :order="index + 1"
                  :correct="option.correct"
                  v-model="
                question.options.find(
                  optionSearch => optionSearch.id === option.id
                ).name
              "
                  class="tw-mb-0"
                  @click:delete="$emit('click:deleteOption', option.id)"
                  @input="[emitInputValue(option.id, $event)]"

              />
            <transition name="fade">
              <div v-if="selected.toLowerCase() === QUESTION_TYPE.NUMERIC">
                <NumericOptions
                    class="tw-mb-2"
                    @input="emitNumericValue"/>
              </div>
            </transition>
            <div
                v-show="selected.toLowerCase() !== QUESTION_TYPE.NUMERIC"
                @click="$emit('click:addOption')"
                id="addOption"
                style="cursor: pointer; color:#008EEF;"
            >
                <span class="d-flex align-center">
                  Add Option
                </span>
            </div>


            <div class="tw-flex tw-justify-end lg:tw-mr-9">
              <BaseButton outlined color="#1E323F" class="mr-3" @click="expand = true" id="cancelQuestion"
                          button-text="Cancel"/>
              <BaseButton color="#1E323F" :disabled="btnDisable" class="white--text card-footer-action" @click="[emitSaveQuestion($event)]"
                          id="saveQuestion" button-text="Save"/>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </v-card>
  </div>
</template>

<script>
import QuestionBox from "./QuestionBox";
import OptionTextField from "./OptionTextField";
import {Edit2Icon, Trash2Icon} from "vue-feather-icons"
import questionType from "../../../../services/utils/questionType";
import NumericOptions from "../../components/NumericOptions";
import BaseButton from "../../../UIComponents/BaseButton";


export default {
  name: "QuestionCard",
  components: {BaseButton, NumericOptions, OptionTextField, QuestionBox, Edit2Icon, Trash2Icon},
  data: () => ({
    expand: false,
    selected: "Single Answer",
    points: 10,
    QUESTION_TYPE: questionType,
    questions: {
      question: "hello",
      type: "single-answer",
      options: ["John", "Jane", "Adam", "Eve", "Mary"]
    },
  }),
  computed:{
    btnDisable: function () {
      const data = this.question;
      const isEmpty = data.options.filter(opt => !opt.name);
      const check = data.options.some(opt => opt.correct);
      return !(!isEmpty.length && check && data.question)
    }
  },
  props: {
    order: [String, Number],
    question: Object,
    optionTextFieldErrorMsg: String,
    // expand:{ type: Boolean,
    //   default: false
    //
    // },
  },
  methods: {
    emitSelectionChange(selected) {
      this.selected = selected
      this.$emit("change:questionType", selected);
    },
    emitInputValue(optionId, value) {
      this.$emit("input:option", {optionId, value});
    },
    emitQuestionValue(value) {
      this.$emit("input:question", value);
    },
    emitNumericValue(value) {
      this.$emit("input:numericQuestion", value);
    },
    emitSaveQuestion(value) {
      this.$emit('click:save', value)
      this.expand = true
    }
  },
};
</script>

<style lang="scss" scoped>
span {
  text-transform: capitalize;
}

.add-option-btn {
  padding: 0 8px !important;
  border-radius: 6px;
}

.option-text-field-error-msg {
  transition: all 0.1s ease-in-out;
  font-weight: lighter;
  color: #960c0c;
}
.fade-enter-active {
  opacity: 0;
}

.options-enter-active {
  transition: all 0.2s;
}

.options-leave-active {
  transition: all 1s;
}

.options-leave-to {
  opacity: 0;
  transform: translateX(-10000px);
}

.options-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.question {
  display: inline;

  p {
    display: inline !important;
  }
}

.cardQue {
  background: #FFFFFF;
  border: 2.50104px solid #F8F8F8;
  box-sizing: border-box;
  border-radius: 15.0104px;
}

.points-box {
  p {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
  }

  .points-input {
    min-width: 80px;
    max-width: 40px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #008EEF;
    padding: 0 8px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
      outline: none;
      width: 100%;
      height: 100%;
    }
  }
}

.card-footer-action {
  padding: 0 2px !important;
}
.addOption{

}
</style>
