<template>
  <div class="lg:tw-w-10/12 ">
    <v-row>
      <v-col></v-col>
      <v-col>
        <div v-if="questions.length">
          <QuestionCard
              v-for="(question, i) in questions"
              :key="i"
              :question="question"
              :order="i + 1"
              @input:numericQuestion="[questionNumericOption(question.id, $event)]"
              @change:questionType="[questionTypeChange(question.id, $event)]"
              @click:addOption="[addOption(question.id)]"
              @click:deleteOption="[deleteOption(question.id, $event)]"
              @input:points="[inputPoints(question.id, $event)]"
              @select="[selectOrDeselectOption(question.id, $event)]"
              @input:option="[optionTextUpdate(question.id, $event)]"
              @input:question="[questionUpdate(question.id, $event)]"
              @click:save="[saveQuestion(question)]"
              @click:deleteQuestion="[deleteQuestion(question.id)]"
          />
        </div>
        <div @click="addQuestion" class="tw-flex tw-justify-start " style="cursor: pointer;color:#008EEF">
          <PlusIcon/>
          <h3
              class="text-capitalize tw-flex-col "
          >
            Add Criteria
          </h3
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {v4 as uuid} from "uuid";
import {PlusIcon} from 'vue-feather-icons'
import QuestionCard from "../reuseables/Question/QuestionCard";
import QUESTION_TYPE from "../../../services/utils/questionType";
import {mapGetters, mapState} from "vuex";
// import _ from "lodash";
import getSiteName from "../../../services/utils";


export default {
  name: "OtherCard",
  components: {QuestionCard, PlusIcon},
  data() {
    return {
      otherCriteria: {
        job_id: null,
        content: "",
        question_type: "",
        options: [],
        answer: [],
        use_criteria: false
      },
      questions: [
        {
          id: uuid(),
          type: QUESTION_TYPE.SINGLE,
          optionTextFieldErrorMsg: "",
          question: "",
          numeric_options: {
            choice_lower_limit: '',
            choice_upper_limit: ''
          },
          options: [],
          optionsInMemory: [],
          points: 5,
        }
      ]
    }
  },
  async created() {
    this.job_id = this.$route.params.jobId
    this.questions = this.questions.map((question) => {
      const options = [];
      question.options.forEach((opt) => {
        options.push({
          id: uuid(),
          name: opt,
        });
      });
      question.quiz_type = this.getQuestionType(question.quiz_type);
      return {...question, options};
    });
    let data = {
      id: this.job_id,
      site_name: getSiteName()
    }
    await this.$store.dispatch('shortlisting/getOtherCriteria', data)
    if (this.getOtherCriteriaDetails.length)this.questions = this.getOtherCriteriaDetails
  },
  computed: {
    ...mapState('shortlisting', ['otherCriterias']),
    ...mapGetters('shortlisting', ['getOtherCriteriaDetails']),
  },
  methods: {
    addOption(id) {
      const option = {
        id: uuid(),
        correct: false,
        name: "",
      };
      // enforcing polymorphic behaviour
      if (!id) return option;
      const question = this.findItemById(this.questions, id);
      if (question.type === QUESTION_TYPE.NUMERIC) return;
      question.options.push(option);
    },
    findItemById(items, id) {
      return items.find((item) => item.id === id);
    },
    questionTypeChange(id, selected) {
      const question = this.findItemById(this.questions, id);
      console.log(selected);
      selected = this.getQuestionType(selected);
      if (
          selected === QUESTION_TYPE.MULTIPLE &&
          question.options.length === 2
      ) {
        question.options.push(this.addOption());
      }
      if (selected === QUESTION_TYPE.MULTIPLE && question.options.length === 0)
        question.options = this.createOptions(3);
      if (selected === QUESTION_TYPE.SINGLE && question.options.length === 0)
        question.options = this.createOptions(1);
      if (selected === QUESTION_TYPE.NUMERIC) question.options = [];
      question.type = selected;
      question.options = []
    },
    createOptions(length = 2) {
      const options = [];
      for (let i = 0; i < length; i++) {
        const option = this.addOption();
        if (i === 0) option.correct = true;
        options.push(option);
      }
      return options;
    },
    deleteOption(questionId, optionId) {
      const question = this.findItemById(this.questions, questionId);
      let {quiz_type: type, options} = question;
      console.log(type, options);
      type = this.getQuestionType(type);
      if (type === QUESTION_TYPE.SINGLE && options.length === 2) {
        question.optionTextFieldErrorMsg =
            "For single option selection, options must not be below two (2).";
        setTimeout(() => (question.optionTextFieldErrorMsg = ""), 3000);
        return;
      } else if (type === QUESTION_TYPE.MULTIPLE && options.length === 3) {
        question.optionTextFieldErrorMsg =
            "For multiple option selection, options must not be below three (3).";
        setTimeout(() => (question.optionTextFieldErrorMsg = ""), 3000);
        return;
      }
      question.options.splice(
          question.options.findIndex((option) => option.id === optionId),
          1
      );
    },
    // @todo numeric values
    // question.numeric_option[value.type] = value.value

    questionNumericOption(id, value) {
      console.log(id, value)
      const question = this.findItemById(this.questions, id);
      question.numeric_options[value.type] = value.value
    },
    inputPoints(id, point) {
      const question = this.findItemById(this.questions, id);
      question.points = +point;
    },
    selectOrDeselectOption(questionId, optionId) {
      const question = this.findItemById(this.questions, questionId);
      const option = this.findItemById(question.options, optionId);
      console.log(question);
      if (question.type === QUESTION_TYPE.SINGLE) {
        console.log(question.type);
        question.options.forEach((option) => (option.correct = false));
        option.correct = true;
      } else if (
          this.getQuestionType(this.getQuestionType(question.type)) === QUESTION_TYPE.MULTIPLE
      ) {
        option.correct = !option.correct;
      }
    },
    optionTextUpdate(questionId, payload) {
      const question = this.findItemById(this.questions, questionId);
      const option = this.findItemById(question.options, payload.optionId);
      option.name = payload.value;
    },
    questionNumericUpdate(id, data) {
      const question = this.findItemById(this.questions, id);
      question.numeric_options[data.type] = data.value
      // const num = this.findItemById(question.options, data.optionId);
      // num.name = data.value;

    },
    questionUpdate(questionId, value) {
      const question = this.findItemById(this.questions, questionId);
      question.question = value;
    },
    deleteQuestion(id) {
      this.questions = this.questions.filter((question) => question.id !== id);
    },
    async saveQuestion(question) {
      const data = {}
      data.job_id = this.$route.params.jobId
      data.content = question.question
      data.question_type = question.type
      data.use_criteria = true
      data.site_name = getSiteName()
      if (question.type === 'numeric') {
        if (+question.numeric_options.choice_upper_limit < +question.numeric_options.choice_lower_limit) {
          this.$displaySnackbar({
            message: "Maximum range should be higher than Minimum range",
            success: false
          })
          return
        }
        data.choice_lower_limit = question.numeric_options.choice_lower_limit
        data.choice_upper_limit = question.numeric_options.choice_upper_limit
      } else {
        data.options = question.options.map(opt => opt.name)
        data.answer = question.options.filter((option) => (option.correct === true)).map(opt => opt.name);
      }
      try {
        // let isEmptyStr = data.answer.filter(str => str.trim().length <= 0)
        // console.log(data.answer,isEmptyStr)
        if (data.content === '' || !data.answer.length) {
          this.$displaySnackbar({
            message: 'Kindly fill the question/answer field',
            success: false
          })
          return
        }

        if (question.id && question.job){
          data.criteria_id = question.id
          await this.$store.dispatch('shortlisting/updateOtherCriterias',data)
        }else {
          await this.$store.dispatch('shortlisting/createOtherCriterias', data)
        }
        this.$displaySnackbar({
          message: 'Question Saved',
          success: true
        })
        this.expand = true
      } catch (e) {
        this.$displaySnackbar({
          message: e.response.data.detail,
          success: false
        })
      } finally {
        console.log('what happened')
      }
    },
    getQuestionType(type) {
      if (type === QUESTION_TYPE.SINGLE) return "Single Answer";
      else if (type === QUESTION_TYPE.MULTIPLE) return "Multiple Answer";
      else if (type === QUESTION_TYPE.NUMERIC) return "Numeric";
      else if (type === "Single Answer") return QUESTION_TYPE.SINGLE;
      else if (type === "Multiple Answer") return QUESTION_TYPE.MULTIPLE;
      else if (type === "Numeric") return QUESTION_TYPE.NUMERIC;
    },
    addQuestion() {
      const question = {
        id: uuid(),
        type: QUESTION_TYPE.SINGLE,
        optionTextFieldErrorMsg: "",
        question: "",
        options: [],
        numeric_options: {
          choice_lower_limit: '',
          choice_upper_limit: ''
        },
        optionsInMemory: [],
        points: 5,
      };
      question.options = this.createOptions();
      this.questions.push(question);
    },
    save() {
      console.log('shshgd')
    }
  }
}
</script>

<style scoped>

</style>