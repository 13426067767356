<template>
  <div class="tw-pb-36 lg:tw-px-14 tw-pt-6"  >
    <v-row  class="light--text flex-column" v-for="(field, index) in fieldData" :key="index">
      <v-col v-if="field.title !== 'Certification'" class="tw-mb-5 tw-flex tw-justify-between align-center tw-flex-wrap">
        <v-checkbox
            v-model="field.enable"
            :label=field.title
            color="#8F47F4"
        ></v-checkbox>
          <InputOptions id="inputOptions" :field="field"/>
      </v-col>
      <v-divider v-if="field.title !== 'Certification'"></v-divider>
    </v-row>

    <div class="tw-flex-wrap tw-flex tw-justify-between align-center tw-pt-7">
      <v-checkbox
          v-model="other"
          color="#8F47F4"
          label="Other"
          id="other"
      ></v-checkbox>
      <small>Create custom criteria to filter applicants. Criteria would be
        displayed as a questionnaire to the applicants. </small>
    </div>

      <OtherCard class="lg:tw-float-right" v-show="other"/>
  </div>
</template>

<script>
import InputOptions from "./InputOptions";
import OtherCard from "./OtherCard";
import {mapState} from "vuex";

export default {
  name: "AddShortlistingCriteria",
  components: {OtherCard, InputOptions},
  data() {
    return {
      other: false,
    }
  },
  computed: {
    ...mapState('shortlisting', ['fieldData', 'criteriaDetails', 'otherCriterias']),
  },
  watch: {
    otherCriterias: {
      handler: function (others) {
        this.other = others.length > 0
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  color: #1E323F !important;

}
</style>
