<template>
  <div>
    <div v-if="field.type === 'highest_level_of_education'">
      <small>Specify the highest Level of Education</small>

      <v-combobox
          v-if="field.enable"
          :disabled="!field.enable"
          :items="highestDegree"
          v-model="field.response.highest_level_of_education"
          solo
          id="highest_level_of_education"
          placeholder="Level of Education"
          hide-details
          style="max-width: 400px;"
      >
      </v-combobox>
    </div>


    <div v-if="field.type === 'years_of_experience'">
      <small>Set minimum years of experience</small>

      <v-combobox
          :disabled="!field.enable"
          v-if="field.enable"
          id="years_of_experience"
          solo
          :items="experienceRange"
          v-model="field.response.experience"
          placeholder="1-3"
          hide-details
          style="max-width: 190px;"
      >
      </v-combobox>
    </div>
<!--    <div v-if="field.type === 'certification'" style="max-width: 400px;">-->
<!--      <small>Specify the required specification</small>-->

<!--      <v-combobox-->
<!--          placeholder="Select Certification"-->
<!--          :disabled="!field.enable"-->
<!--          chips-->
<!--          multiple-->
<!--          :items="certification"-->
<!--          id="certification"-->
<!--          v-if="field.enable"-->
<!--          v-model="field.response.certification"-->
<!--          solo-->
<!--          hide-details-->
<!--          style="max-width: 400px;"-->
<!--      >-->
<!--        <template v-slot:selection="data">-->
<!--          <v-chip-->
<!--              :key="JSON.stringify(data.item)"-->
<!--              v-bind="data.attrs"-->
<!--              :input-value="data.selected"-->
<!--              color="#0C8B99"-->
<!--              :disabled="data.disabled"-->
<!--              @click="data.select"-->
<!--              id="removeCertification"-->
<!--          >-->
<!--            <div @click="handleRemoveCertification(data.item)" class="tw-flex tw-text-white">-->
<!--              {{ data.item }}-->
<!--              <XIcon class="tw-w-4 tw-ml-2 "/>-->
<!--            </div>-->
<!--          </v-chip>-->
<!--        </template>-->
<!--      </v-combobox>-->
<!--    </div>-->
    <div v-if="field.type === 'age'">
      <small>Specify the preferred age for this role</small>
      <div v-if="field.enable" class="tw-flex">
        <v-text-field
            :disabled="!field.enable"
            solo
            id="age_lower_limit"
            type="number"
            placeholder="21"
            v-model="field.response.age_lower_limit"
            hide-details
            style="max-width: 190px;"

        ></v-text-field>
        <MinusIcon class="tw-mt-3 tw-mr-1"/>
        <v-text-field
            :disabled="!field.enable"
            placeholder="25"
            id="age_upper_limit"
            type="number"
            v-model="field.response.age_upper_limit"
            solo
            hide-details
            style="max-width: 190px"

        ></v-text-field>
      </div>

    </div>

    <div v-if="field.type === 'location'">
      <small>Specify the preferred location of applicants</small>

      <div v-if="field.enable">
        <div class="tw-mb-8">
          <v-combobox
              @click="clearStateAndCity"
              :disabled="!field.enable || disableStateAndCities"
              v-model="field.response.country"
              id="country"
              :items="countries"
              return-object
              item-value="name"
              solo
              item-text="name"
              hide-details
              placeholder="Select Location"
              style="max-width: 400px; "
          >

          </v-combobox>
        </div>
        <div class="d-flex">
<!--          <v-combobox-->
<!--              :disabled="!field.enable || disableStateAndCities"-->
<!--              solo-->
<!--              :items="state"-->
<!--              id="state"-->
<!--              v-model="field.response.state"-->
<!--              label="State"-->
<!--              placeholder="Select State"-->
<!--              hide-details-->
<!--              style="max-width: 400px;"-->

<!--          ></v-combobox>-->
          <!--          <MinusIcon class="tw-mt-3 tw-mr-1"/>-->
          <!--          <v-combobox-->
          <!--              :disabled="!field.enable || disableStateAndCities"-->
          <!--              v-model="field.response.city"-->
          <!--              solo-->
          <!--              label="City"-->
          <!--              id="city"-->
          <!--              :items="getLga"-->
          <!--              placeholder="Select City"-->
          <!--              hide-details-->
          <!--              style="max-width: 190px"-->

          <!--          ></v-combobox>-->
        </div>
        <v-checkbox
            v-model="field.response.is_remote"
            label="Remote"
            id="remote"
            color="#8F47F4"
        ></v-checkbox>
      </div>

    </div>

    <div v-if="field.type === 'salary'">
      <small>Filter applicant by salary expectation</small>

      <div v-if="field.enable">
        <div class="tw-mb-8">
          <v-combobox
              :disabled="!field.enable"
              v-model="field.response.currency"
              solo
              hide-details
              :items="returnCurrenciesName"
              placeholder="Currency"
              id="currency"
              style="max-width: 400px;"
          >
          </v-combobox>
        </div>
<!--        <div class="d-flex">-->
<!--          <v-autocomplete-->
<!--              :disabled="!field.enable"-->
<!--              v-model="field.response.salary_range"-->
<!--              solo-->
<!--              :items="salaryRange"-->
<!--              hide-details-->
<!--              id="salary_range"-->
<!--              placeholder="Select Range"-->
<!--              style="max-width: 400px;"-->

<!--          ></v-autocomplete>-->
<!--        </div>-->

        <div class="tw-flex tw-justify-between">
          <v-text-field
              solo
              id="minimumSalary"
              type="number"
              label="Lower"
              placeholder="21,000"
              v-model="field.response.salary_lower_limit"
              hide-details
              style="max-width: 100px;"

          ></v-text-field>
          <v-text-field
              placeholder="25,000"
              id="maximumSalary"
              label="Upper"
              type="number"
              v-model="field.response.salary_upper_limit"
              solo
              hide-details
              style="max-width: 100px"

          ></v-text-field>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {MinusIcon} from 'vue-feather-icons';
// import state from "../../../assets/state";
import ENUM_CERTIFICATION from "@/components/shortlistingCriteria/reuseables/enumCertification";
import LGA from "../../../assets/lga";
// import country from "../../../assets/country";
import worldCurrencies from "../../../assets/currencies.json";
import {getCriteriaCountries} from "@/services/api/APIService";
import handleLikeMixin from "@/mixins/handleLike.mixin";

export default {
  name: "InputOptions",
  components: {MinusIcon},
  data() {
    return {
      state: [],
      certification: ENUM_CERTIFICATION.certifications,
      switch1: false,
      experience: null,
      currencies: worldCurrencies,
      disableStateCity: false,
      countries: ['Lagos, Nigeria'],
    }
  },
  mixins:[handleLikeMixin],
  mounted() {
    this.getAllCountries()
    // let sal = JSON.parse(sessionStorage.getItem('salary'))
    // if (sal && this.field.type === 'salary' && !this.$route.query.edit){
    //   this.field.response.currency = sal.currency
    //   this.field.response.salary_range = this.convertNumber( sal.minimum_salary) + ' - ' + this.convertNumber( sal.maximum_salary)
    // }
  },
  watch: {
    // 'field.response.salary_range': function (data) {
    //   if (data.includes('and')) {
    //     this.field.response.salary_lower_limit = data.split(' ')[0].replace(/,/, '')
    //     this.field.response.salary_upper_limit = '10000000'
    //   } else if (data === 'Select Range') {
    //     this.field.response.salary_lower_limit = 0
    //     this.field.response.salary_upper_limit = 0
    //     this.field.response.salary_range = null
    //   } else {
    //     this.field.response.salary_lower_limit = data.split(' - ')[0].replace(/,/, '')
    //     this.field.response.salary_upper_limit = data.split(' - ')[1].replace(/,/, '')
    //   }
    // },
    'field.response.experience': {
      handler: function (data) {
        const num = {
          '<1': 0,
          '1-3': 1,
          '4-7': 4,
          '8+': 8,
        }
        this.field.response.years_of_experience = num[data]
      },
    }
  },
  computed: {
    getLga() {
      return LGA[this.field.response.state];
    },
    sortData(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.country.sort((a, b) => a.name.localeCompare(b.name))
    },
    returnCurrenciesName() {
      let returnCurrencies = []
      this.currencies.map((currency) => {
        returnCurrencies.push(currency.name + " (" + currency.symbol_native + ") ")
      })
      return returnCurrencies;
    },
    highestDegree() {
      return [
        "BSc",
        "MSc",
        "PhD",
        "OND",
        "HND",
        "SSCE",

      ];
    },
    experienceRange() {
      return [
        "<1",
        "1-3",
        "4-7",
        "8+"
      ]
    },
    salaryRange() {
      return [
        'Select Range',
        '30,000 - 50,000',
        '50,000 - 100,000',
        '100,000 - 150,000',
        '150,000 - 200,000',
        '200,000 - 250,000',
        '250,000 - 300,000',
        '300,000 - 350,000',
        '350,000 - 400,000',
        '400,000 - 450,000',
        '450,000 - 500,000',
        '500,000 and above',
      ];
    },
    disableStateAndCities() {
      return this.field.response.is_remote
    }
  },
  methods: {
    getAllCountries() {
      getCriteriaCountries().then(res => {
        res.data.data.map((country) => {
          country.states.map((state) => {
            this.countries.push(state.name + ", "  + country.name)
          })
        })
      })
          .catch(err => {
            console.log(err.response.data.detail)
          })
    },
    // getAllCountries() {
    //   getCountries().then(res => {
    //     const data = JSON.parse(JSON.stringify(res.data))
    //     this.country = data
    //   }).catch(err =>{
    //     this.$displaySnackbar({message:err, success:false})
    //   })
    // },
    clearStateAndCity() {
      this.field.response.state = ""
      this.field.response.city = ""
    },
    // getStates(val) {
    //   getStates(val.iso2).then(res => {
    //     const data = JSON.parse(JSON.stringify(res.data))
    //     this.state = data.map(state => state.name).sort()
    //   }).catch(err =>{
    //     this.$displaySnackbar({message:err, success:false})
    //   })
    //   this.field.response.country = val.name
    //
    // },
    handleRemoveCertification(framework) {
      this.field.response.certification = this.field.response.certification.filter(item => item !== framework)
    },
  },

  props: {
    field: {
      type: Object,
      default: () => {
      },
    }
  },
}
</script>

<style scoped>

</style>
