<template>
    <div style="max-width: 380px">
      <v-text-field
          @input="emitValue"
          :placeholder="propQuestion || 'Question?'"
          class="my-6"
          id="question"
          :value="propQuestion"
          solo
          hide-details

      ></v-text-field>
      <v-select
          :items="items"
          label="Question Type"
          v-model="selected"
          class="my-6"
          @change="$emit('change', selected)"
          :menu-props="menuProps"
          solo
          hide-details
          id="select"
      />
    </div>
</template>

<script>

export default {
  name: "QuestionBox",
  components: {},
  data: () => ({
    selected: 'Single Answer',
    items: ['Single Answer', 'Multiple Answer', 'Numeric'],
    menuProps: {
      bottom: true,
      offsetY: true,
    },
  }),
  methods: {
    emitValue(value) {
      this.$emit("input", value);
    },
  },
  props: {
    propSelected: String,
    propQuestion: String
  },
  watch: {
    propSelected: {
      handler: function (value) {
        if (value) {
          const selectables = {
            'multiple_answer': 'Multiple Answer',
            'single_answer': 'Single Answer'
          }
          this.selected = selectables[value] ?? value
        }
      },
      immediate: true
    },
    // propQuestion: {
    //   handler: function (value) {
    //     if (value) {
    //       this.q
    //     }
    //   }
    // }
  }
};
</script>

<style scoped>

</style>