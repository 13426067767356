<template>
  <div class="tw-flex" style="max-width: 380px">
    <v-text-field
        solo
        type="number"
        placeholder="2"
        @input="$emit('input',{type: 'choice_lower_limit', value: $event})"
        hide-details

    ></v-text-field>
    <MinusIcon class="tw-mt-3 tw-mr-1"/>
    <v-text-field
        placeholder="6"
        type="number"
        @input="$emit('input',{type: 'choice_upper_limit', value: $event})"
        solo
        hide-details

    ></v-text-field>
  </div>
</template>

<script>
import {MinusIcon} from 'vue-feather-icons';

export default {
  name: "NumericOptions",
  components: {MinusIcon},
  props:{
    id: String,
  }

}
</script>

<style scoped>

</style>